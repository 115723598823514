<template>
  <div class="main">

    <div
        v-if="!is_blocked"
        class="item__column item__100"
    >



    <div class="bonus__top--amount">
      <h3 class="pa-4" v-if="today_amount">Продажа на сегодня: <span class="bonus__value">{{ formatPrice(today_amount) }} тнг</span></h3>
      <h3 class="pa-4" v-else>Продажа на сегодня: 0 тнг</h3>
    </div>

    <div class="bonuses">
      <div class="item__row item__ac bonuses__logo">
        <i class="mdi mdi-gift mr-2"></i>
      </div>

      <div class="item__row item__ac bonuses__header">
        <h3>{{ company_name }}</h3>
        <i class="mdi mdi-logout" @click="logoutAccount()"></i>
      </div>


      <div class="bonuses__search__area  item__row item__ac">
        <input
            v-model="searchText"
            @input="searchBonus"
            placeholder="Поиск клиента"
            class="bonuses__search"
            type="tel"
        />
      </div>

      <div class="item__column" v-if="showLoader">
        <div
            class="item__column bonuses__list mb-6"
            v-for="(bonus, index) in bonuses"
            :key="index"
        >
          <div class="item__row item__ac bonuses__right__actions">
            <i class="mdi mdi-account-edit-outline" @click="getBonus(bonus.id)"></i>
          </div>

          <div class="bonuses__list__name mr-2 mb-3">
            <i class="mdi mdi-account mr-1"></i>
            <p>{{ bonus.name }}</p>
          </div>
          <a class="bonuses__list__phone mr-2" :href="setWhatsappLink(bonus.phone,bonus)"><i class="mdi mdi-whatsapp mr-2"></i>{{ formatPhoneNumber(bonus.phone) }}</a>

          <p class="mr-10 bonuses__amount">
            <b v-if="settings && settings.type ==='bonus'">+{{ bonus.bonus }} ₸</b>
            <b v-else>
              <span class="bonuses__end" v-if="bonus.logs.length+1 === count_value">{{ bonus.logs.length  }} покупка ({{ count_value}}-я покупка бесплатно)</span>
              <span v-else>{{ bonus.logs.length }} покупка</span>
            </b>
          </p>


          <button
              v-if="settings.type ==='bonus'"
              class="bonuses__sub"
              @click="openFreshBonus(bonus.id)"
          >
            <p>Обнулить бонус</p>
          </button>



          <div class="item__column bonus__logs">
            <div class="bonus__logs--history item__row item__ac">

              <div class="bonus__logs--item">
                <i @click="openHistory(bonus.all_logs)" class="mdi mdi-history"></i>
              </div>

              <div class="bonus__logs--item" v-if="settings.type ==='bonus'">
                <i @click="openUseModal(bonus)" class="mdi mdi-minus"></i>
              </div>

              <div class="bonus__logs--item">
                <i @click="openAddModal(bonus)" class="mdi mdi-plus"></i>
              </div>

            </div>

          </div>


        </div>
      </div>

      <div class="item__column mb-2" v-else>
        <v-skeleton-loader
            class="mb-2"
            v-for="(jj, index) in 5"
            :key="index"
            type="article"
        ></v-skeleton-loader>
      </div>


      <v-dialog max-width="600" v-model="zero_bonus">
        <v-card>
          <div class="pa-5 item__column modal__bonus">
            <p class="modal__bonus__title">Обнулить бонус ?</p>

            <div class="modal__bonus__variants item__row item__ac">
              <button
                  type="submit"
                  class="modal__bonus__btn item__pointer modal__bonus__btn--green"
                  @click="freshBonus"
              >
                <p>Да</p>
              </button>

              <button
                  type="submit"
                  class="modal__bonus__btn item__pointer modal__bonus__btn--red"
                  @click="zero_bonus=false"
              >
                <p>Нет</p>
              </button>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog max-width="600" v-model="bonus_use">
        <v-card>
          <div class="pa-5 item__column modal__bonus">
            <p class="modal__bonus__title">Списать бонус</p>
            <div class="item__column modal__bonus__result">
              <p>{{ info.name }}</p>
              <p class="mb-2 mt-3">{{ info.bonus !== 0 ? info.bonus + ' ₸' : '' }} </p>
            </div>
            <div class="item__column modal__bonus__label">
              <p class="mb-4">Телефон</p>
              <p>{{ info.phone }}</p>
            </div>
            <div class="item__column modal__bonus__label">
              <p>Сумма к списанию</p>
              <input type="tel" v-model="sub_amount" required/>
            </div>

            <button
                type="submit"
                class="modal__bonus__btn item__pointer"
                @click="useBonus()"
            >
              <p>Списать бонус</p>
            </button>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog max-width="600" v-model="bonus_add">
        <v-card>
          <div class="pa-5 item__column modal__bonus">
            <p class="modal__bonus__title">Добавить бонус</p>

            <span class="bonuses__end" v-if="info.logs && info.logs.length+1 === count_value">{{ info.logs.length }} ({{ count_value}}-я покупка бесплатно)</span>

            <div class="item__column modal__bonus__result">
              <p>{{ info.name }}</p>
              <p class="modal__bonus__result--value"  v-if="settings.type ==='bonus'">{{ info.bonus !== 0 ? info.bonus + ' ₸' : '' }} </p>
              <p class="modal__bonus__result--value" v-else> {{ info.logs ? info.logs.length + ' покупка' : '' }}</p>

            </div>

            <div class="item__column modal__bonus__label">
              <p>Телефон</p>
              <p>{{ info.phone }}</p>
            </div>

            <div class="item__column modal__bonus__label">
              <p>Сумма</p>
              <input v-model="amount_price" type="tel"/>
            </div>

            <div
                v-if="settings.type !=='bonus'"
                class="item__column modal__bonus__label"
            >
              <p>Количество</p>
              <input v-model="count" type="tel"/>
            </div>

            <button
                type="submit"
                class="modal__bonus__btn item__pointer"
                @click="addBonus()"
            >
              <p>Добавить бонус</p>
            </button>
          </div>
        </v-card>
      </v-dialog>


      <v-dialog v-model="showHistory" max-width="500">
        <v-card>
          <div class="bonus__history">
            <h3 class="mb-4">История покупок</h3>
            <div
                v-for="item in logs"
                :key="item.id"
                class="item__column mb-4"
            >
              <p><b>{{ formatDate(item.created_at) }}</b></p>
              <p>Покупка:    <b>{{ item.price }} тнг</b> </p>
              <p>Бонус: <b>{{ item.bonus }}</b></p>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="updateDialog" max-width="500">
        <v-card>
          <form
              action=""
              @submit.prevent="updateBonusForm"
              class="green__column item__column item_ac green__message"
              style="padding: 20px"
          >
            <div class="green__column green__input">
              <p>Имя</p>
              <v-text-field
                  v-model="name"
                  label="Номер карты"
                  required
                  outlined
              ></v-text-field>
            </div>
            <div class="green__column green__input">
              <p>Номер телефона</p>
              <v-text-field
                  v-model="phone"
                  label="Номер телефона"
                  required
                  outlined
              ></v-text-field>
            </div>
            <div class="green__column green__input">
              <p>Цена</p>
              <v-text-field
                  v-model="price"
                  label="Цена"
                  required
                  outlined
              ></v-text-field>
            </div>
            <button type="submit" class="button">Отправить</button>
          </form>
        </v-card>
      </v-dialog>
    </div>


    <v-dialog max-width="600" v-model="open_settings">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Настройка</p>
          <div v-if="settings && settings.type === 'bonus'" class="item__column modal__bonus__label">
            <p>Процент от покупки %</p>
            <input type="number" v-model="bonus_value" required/>
          </div>

          <div v-else class="item__column modal__bonus__label">
            <p>N-ый покупка</p>
            <input type="number" v-model="count_value" required/>
          </div>
          <button
              type="submit"
              class="modal__bonus__btn item__pointer"
              @click="saveSettings()"
          >
            <p>Сохранить</p>
          </button>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="open_bonus_clients">
      <v-card>
        <div class="pa-5 item__column modal__bonus modal__bonus--link">
          <div class="item__column mb-4" v-for="item in bonus_clients_list" :key="item.id">
            <p>{{ item.bonus.name }}</p>
            <a :href="setWhatsappLink(item.bonus.phone,item.bonus)" target="_blank"><i class="mdi mdi-whatsapp mr-2"></i>{{ formatPhoneNumber(item.bonus.phone) }}</a>
          </div>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="open_statistics">
      <v-card>
        <div class="statistics pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Статистика</p>

          <div class="statistics__header item__row">
            <p
                @click="setFirstGraph(2)"
                :class="{ 'statistics__header--label': true, 'statistics__header--active': first__graph === 2 }">
              Клиенты
            </p>
            <p
                @click="setFirstGraph(1)"
                :class="{ 'statistics__header--label': true, 'statistics__header--active': first__graph === 3 }">
              Продажи
            </p>

          </div>

          <WeekBar v-if="first__graph === 1" />
          <div v-else class="bonus__statistics--group">
            <div class="bonus__statistics item__row item__ac"
                 @click="getClients('inactive_clients',statistics.inactive_clients)">
              <p class="bonus__statistics--name">Неактивные клиенты</p>
              <p class="bonus__statistics--value">{{ statistics.inactive_clients }}</p>
            </div>
<!--            <div class="bonus__statistics item__row item__ac" @click="getClients('new_clients', statistics.new_clients )">-->
<!--              <p class="bonus__statistics&#45;&#45;name">Новые клиенты</p>-->
<!--              <p class="bonus__statistics&#45;&#45;value">{{ statistics.new_clients }}</p>-->
<!--            </div>-->
            <div class="bonus__statistics item__row item__ac"
                 @click="getClients('loyal_clients',statistics.loyal_clients)">
              <p class="bonus__statistics--name">Лояльные клиенты</p>
              <p class="bonus__statistics--value">{{ statistics.loyal_clients }}</p>
            </div>
<!--            <div class="bonus__statistics item__row item__ac" @click="getClients('vip_clients',statistics.vip_clients)">-->
<!--              <p class="bonus__statistics&#45;&#45;name">VIP клиенты</p>-->
<!--              <p class="bonus__statistics&#45;&#45;value">{{ statistics.vip_clients }}</p>-->
<!--            </div>-->

            <div class="bonus__statistics item__row item__ac" @click="getClients('all_clients',statistics.all_clients)">
              <p class="bonus__statistics--name">Все клиенты</p>
              <p class="bonus__statistics--value">{{ statistics.all_clients }}</p>
            </div>

          </div>

        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="create_user">
      <v-card>

        <div class="item__column">
          <div
              class="item__column mt-2 pl-4"
              v-for="worker in workers"
              :key="worker.id"
          >
            <div class="item__row item__ac">
              <div class="item__column">
                <p class="mb-1">{{ worker.user.name }}</p>
                <p>{{ worker.user.email }} </p>
              </div>
              <i @click="openUpdateUserModal(worker.user)" class="ml-4 mdi mdi-lead-pencil"></i>

              <i @click="openDeleteUserModal(worker.user)" class="ml-4 mdi mdi-delete-forever"></i>
            </div>
          </div>
        </div>

        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Создать кассу</p>
          <div class="item__column modal__bonus__label">
            <p>ФИО</p>
            <input v-model="fio"/>
          </div>

          <div class="item__column modal__bonus__label">
            <p>E-mail</p>
            <input v-model="login" type="email"/>
          </div>

          <div class="item__column modal__bonus__label">
            <p>Пароль</p>
            <input v-model="password" type="password"/>
          </div>

          <button
              type="submit"
              class="modal__bonus__btn item__pointer"
              @click="createUser"
          >
            <p>Сохранить</p>
          </button>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="update_user">
      <v-card>

        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Редактировать</p>
          <div class="item__column modal__bonus__label">
            <p>ФИО</p>
            <input v-model="fio"/>
          </div>

          <div class="item__column modal__bonus__label">
            <p>E-mail</p>
            <input v-model="login" type="email"/>
          </div>

          <div class="item__column modal__bonus__label">
            <p>Пароль</p>
            <input v-model="password" type="password"/>
          </div>

          <button
              type="submit"
              class="modal__bonus__btn item__pointer"
              @click="updateUser"
          >
            <p>Сохранить</p>
          </button>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="delete_user">
      <v-card>

        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Удалить данного пользователя ? </p>
          <div class="item__column modal__bonus__label">
            <p>{{ fio +' '+login}}</p>
          </div>



          <div class="item__row item_-ac">
            <button
                type="submit"
                class="modal__bonus__btn item__pointer mr-2"
                @click="deleteUser"
            >
              <p>Да</p>
            </button>
            <button
                type="submit"
                class="modal__bonus__btn item__pointer"
                @click="delete_user = false"
            >
              <p>Нет</p>
            </button>
          </div>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="bonus_create">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Cоздать бонусную карту</p>
          <div class="item__column modal__bonus__label">
            <p class="mb-2">ФИО</p>
            <input v-model="fio"/>
          </div>

          <div class="item__column modal__bonus__label">
            <p  class="mb-2">Телефон</p>
            <masked-input
                class="sms__digits"
                placeholder="8 XXX XXX XX XX"
                v-model.trim="phone"
                mask="8(111) 111-11-11"
                ref="numericInput"
                type="tel"
            />
          </div>


          <div
              v-if="settings.type !== 'bonus'"
              class="item__column modal__bonus__label"
          >
            <p  class="mb-2">Количество</p>
            <input v-model="count" type="tel" />
          </div>

          <div class="item__column modal__bonus__label">
            <p  class="mb-2">Сумма</p>
            <input v-model="amount_price" type="tel" />
          </div>


          <button
              type="submit"
              class="modal__bonus__btn item__pointer"
              @click="createBonus"
          >
            <p>Создать бонусный счет</p>
          </button>
        </div>
      </v-card>
    </v-dialog>

    <div class="main__bottom">

      <div @click="open_statistics = true">
        <i class="mdi mdi-poll"></i>
      </div>

      <div @click="bonus_create = true">
        <i class="mdi mdi-plus-circle"></i>
      </div>

      <div v-if="role == 1" @click="open_settings = true">
        <i class="mdi mdi-clipboard-text"></i>
      </div>
    </div>
    </div>
    <div class="block__company item__column item__100 item__jc" v-else>

        <i class="mdi mdi-block-helper"></i>
        <p>Вам нужно оплатить ежемесячный платёж, перейдя по ссылке ниже: 6900 тг.</p>
        <a href="https://pay.kaspi.kz/pay/z2va7ckf"></a>
<!--        <a href="https://wa.me/+77078709561">Обратитесь к администратору</a>-->
    </div>
  </div>
</template>

<script>
import MaskedInput from "vue-masked-input";
import {mapGetters} from "vuex";
import WeekBar from "../views/WeekBar.vue";
import { debounce } from 'lodash';

export default {
  name: "Main",
  components: {
    MaskedInput,
    WeekBar
  },
  data() {
    return {
      is_blocked: false,
      count: 0,
      value:  "",
      show:  0,
      options: {
        keyRandomize:  true,
        randomizeClick:  true,
        fixDeleteKey:  false,
      },
      first__graph: 2,
      create_user: '',
      login: '',
      password: '',
      role: '',
      statistics: [],
      open_statistics: false,
      bonus_value: '',
      count_value: '',
      current_route: this.$route.path,
      bonus_create: false,
      bonus_add: false,
      bonus_use: false,
      sub_amount: 0,
      bonus: 0,
      phone: '',
      fio: '',
      amount_price: '',
      bonusesList: [],
      open_settings: false,
      company_name: '',
      workers: [],
      company: '',
      update_user: false,
      delete_user: false,
      user_id: '',
      open_bonus_clients: false,
      bonus_clients_list: [],
      today_amount: '',
      settings: '',

      showLoader: false,
      bonuses: [],
      updateDialog: false,
      card_number: "",
      price: "",
      searchText: "",
      name: "",
      logs: [],
      showHistory: false,
      info: '',
      zero_bonus: false,
      selected_bonus_id: 0
    }
  },
  methods: {
    formatPhoneNumber(phoneNumberString) {
      let filter = "";
      if (phoneNumberString.slice(0, 1) == "8") {
        filter = phoneNumberString.replace("8", "");
      }

      let phone_number =  "8 " +
          filter.replace(/\D+/g, "").replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4")
      return phone_number;
    },
    setFirstGraph(value) {
      this.first__graph = value;
    },
    formatPrice(price) {
      // Format the price with two decimal places
      let formattedPrice = price.toFixed(2);
      // Add spaces for thousands separator
      formattedPrice = formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      // Add currency symbol (assuming USD)
      return  formattedPrice;
    },
    async getTodayAmount() {
      try {
        const response = await this.$axios.get(`${this.$API_URL}bonus/today/amount`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        this.today_amount = response.data;
      } catch (error) {
        console.error("Error fetching today's amount:", error);
      }
    },
    getClients(type, value) {
      if (value === 0)
        return false;

      this.open_bonus_clients = true;
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus/clients?type=` + type,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.bonus_clients_list = res.data
          })
    },
    openUpdateUserModal(worker) {
      this.update_user = true
      this.login = worker.email
      this.fio = worker.name
      this.user_id = worker.id
    },
    openDeleteUserModal(worker) {
      this.delete_user = true
      this.login = worker.email
      this.fio = worker.name
      this.user_id = worker.id
    },
    getWorkers() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus/workers?company_id=` + this.company.id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.workers = res.data
          })
    },
    deleteUser() {
      this.$axios({
        method: 'delete',
        url: `${this.$API_URL}user/delete/` + this.user_id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getWorkers()
            this.delete_user = false
            this.email = ''
            this.name = ''
            this.fio = ''
            this.user_id = ''
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    updateUser() {
      let data = {
        email: this.login,
        password: this.password,
        company_id: this.company.id,
        name: this.fio
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}bonus/update/user/` + this.user_id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getWorkers()
            this.update_user = false
            this.email = ''
            this.name = ''
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    createUser() {
      let data = {
        email: this.login,
        password: this.password,
        company_id: this.company.id,
        name: this.fio
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}bonus/create/user`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getWorkers()
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    getStatistics() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}statistics`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            console.log("result", res)

            this.statistics = res.data
          })
    },

    saveSettings() {

      let data = {
        count_value: this.count_value,
        bonus_value: this.bonus_value,
      };


      this.$axios({
        method: 'post',
        url: `${this.$API_URL}settings`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });

    },
    createBonus() {
      if (this.phone.replace(/[^0-9]/g, "").length < 11) {
        this.$toast.open({
          message: "Поле телефон не правильно заполнено",
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }
      if (this.fio === "") {
        this.$toast.open({
          message: "Заполните поле имя",
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }
      let data = {
        name: this.fio,
        phone: this.phone.replace(/[^0-9]/g, ""),
        amount: this.amount_price,
        count: this.count
      };
      this.$axios({
        method: 'post',
        url: `${this.$API_URL}bonus/create`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then(() => {

            this.$toast.open({
              message: "Успешно сохранено",
              type: "success",
              position: "bottom",
              duration: 5000,
              queue: true,
              style: {
                padding: "20px"
              },
              classes: ["custom-toast-class"],
            });


            this.use_modal_create = false;
            this.phone = "";
            this.fio = "";
            this.amount_price = "";
            this.count = 0

            this.getBonuses();
            // this.$refs.bonusesList.getGuest();

            this.bonus_create = false;
            this.getTodayAmount()
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    callTodayAmount() {
      this.$emit('today-amount');
    },
    openFreshBonus(id) {
      this.zero_bonus=true
      this.selected_bonus_id = id
    },
    freshBonus() {
      let data = {}
      this.$axios({
        method: 'put',
        url: `${this.$API_URL}bonus/fresh/${this.selected_bonus_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses()
            this.zero_bonus = false
            this.selected_bonus_id = 0
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    logoutAccount() {
      localStorage.setItem('access_token','')
      this.$router.push('/')
    },
    setWhatsappLink(phone, bonus) {
      let message = `
      Құрметті клиент\nСіздің жиналған бонусыңыз: ${bonus.bonus} теңге. \n © ${this.company_name}.
      \nУважаемый клиент\nВаш накопленный бонус: ${bonus.bonus} тенге. \n С уважением,  © ${this.company_name}`;

      return `https://wa.me/${phone.replace("8", "7")}?text=${encodeURIComponent(message)}`;
    },
    formatDate(date) {
      return date;
    },
    useBonus() {
      if (this.info.phone.replace(/[^0-9]/g, "").length !== 11) {
        this.$toast.open({
          message: "Введите телефон",
          type: "error",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      } else if (!this.sub_amount) {
        this.$toast.open({
          message: "Введите сумму к списанию",
          type: "error",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }
      let data = {
        phone: this.info.phone.replace(/[^0-9]/g, ""),
        sub_amount: this.sub_amount
      }

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}bonus/use`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses();
            this.bonus_use = false
            this.sub_amount = 0
            this.callTodayAmount()
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    addBonus() {
      if (this.amount_price === "") {
        this.$toast.open({
          message: "Заполните поле сумма",
          type: "warning",
          position: "bottom",
          duration: 5000,
          queue: true,
        });
      }

      let data = {
        phone: this.info.phone.replace(/[^0-9]/g, ""),
        amount: this.amount_price,
        name: this.info.name,
        count: this.count
      };


      this.$axios({
        method: 'post',
        url: `${this.$API_URL}bonus/add`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: "Бонус успешно добавлено",
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses();
            this.phone = "";
            this.amount_price = ""
            this.bonus_add = false
            this.count = 0
            this.getTodayAmount()
            // this.$parent.getTodayAmount()
            // window.location.reload()
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    openAddModal(bonus) {
      this.info = bonus
      this.bonus_add = true
    },
    openUseModal(bonus) {
      this.info = bonus
      this.bonus_use = true
    },
    openHistory(logs) {
      this.showHistory = true
      this.logs = logs
    },

    debouncedSearchBonus: debounce(function() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus/search?search=${this.searchText}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.bonuses = res.data;
          })
    }, 1000),
    searchBonus() {
      this.debouncedSearchBonus();
    },
    updateBonusForm() {
      let data = {
        id: this.bonus.id,
        name: this.name,
        phone: this.phone,
        price: this.price,
      };
      this.$http
          .post("/update/bonus", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((res) => {
            this.phone = "";
            this.price = "";
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.updateDialog = false;
            this.getBonuses();
          });
    },
    deleteBonus(id) {
      confirm("Удалить запись?");

      let data = {
        id: id,
      };
      this.$http
          .post("/delete/bonus", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((res) => {
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.getBonuses();
          });
    },
    getBonuses() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.bonuses = res.data;
            setTimeout((this.showLoader = true), 5000);
          });
    },
    getBonus(id) {
      this.updateDialog = true;
      const config = {
        headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`},
      };
      this.bonus = "";
      this.$http.get("/get/bonus/by/id?id=" + id, config).then((res) => {
        this.name = res.data.name;
        this.bonus = res.data;
        this.phone = res.data.phone;
        this.price = res.data.bonus;

        console.log(res.data);

        this.name = res.data.name;
        this.card_number = res.data.card_number;
      });
    },
    getUser() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}bonus/user`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            this.settings = res.data.settings
            this.bonus_value = res.data.settings.bonus_value
            this.count_value = res.data.settings.count_value
            this.company_name = res.data.company.name
            this.is_blocked = res.data.company.is_blocked
            this.company = res.data.company
          })
    },
  },
  mounted() {
    this.getTodayAmount()
    this.role = localStorage.getItem('role');
    this.getStatistics()
    // document.querySelector('.sms__digits').setAttribute('inputmode', 'numeric');
    // this.$refs.numericInput.setAttribute('inputmode', 'numeric');
    // this.getBonus()
  },
  watch: {
    $route(to) {
      this.current_route = to.path;
    },
  },
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
  beforeMount() {
    this.getUser()
    this.getBonuses()
  }
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  display: flex;
  flex-direction: column;
}

.active__route {
  color: rgb(0, 62, 116);
}

.main__bottom {
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  div {
    padding: 10px;

    i {
      font-size: 32px;

    }

    .mdi-settings {
      color: black;
    }
  }
}


.modal__bonus {
  .modal__bonus__title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    margin-bottom: 30px !important;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px !important;
  }

  .modal__bonus__result {
    margin-bottom: 10px;

    p {
      font-size: 18px;
      color: #333333;

      span {
        font-weight: bold;

        font-size: 20px;
      }
    }
  }

  .modal__bonus__label {
    margin-bottom: 15px;

    p {
      font-weight: bold;
    }
  }

  input {
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
  }

  .modal__bonus__btn {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    justify-content: center;
    background: black;

    p {
      color: white;
      font-weight: bold;
    }

    i {
      color: white;
    }
  }
}

.v-text-field--outlined fieldset {
  border-color: white;
}

.green__active {
  color: #0069ff;
}

.banners {
  overflow-x: scroll;

  i {
    cursor: pointer;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}

.green__notif_position {
  bottom: 200px;
}

p {
  margin-bottom: 0 !important;
}

.main__top--actions {
  align-self: flex-start;

  button {
    padding: 10px;
    background: black;
    color: white;
    border-radius: 10px;
    width: 200px;
    margin-top: 10px;
  }
}

.bonus__statistics {
  margin-bottom: 16px;
  cursor: pointer;
  background-color: #fee0e0;


  p {
    font-size: 14px;
  }

  .bonus__statistics--value {
    font-weight: bold;
  }
}

.bonus__statistics:hover {
  opacity: 0.7;
}

.custom-toast-class {
  padding: 20px !important;
}

.bonus__value {
  color: darkgreen;
}

.bonus__statistics--group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .bonus__statistics:nth-child(1) {
    background-color: #def1dd;
  }
  .bonus__statistics:nth-child(2) {
    background-color: #e4eaff;
  }
  .bonus__statistics:nth-child(3) {
    background-color: #f78acb;
  }
  .bonus__statistics:nth-child(4) {
    background-color: #5959ff;
  }

  .bonus__statistics {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    border-radius: 5px;
    border: 2px solid black;
  }

}


.bonus__top--amount {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  padding-bottom: 25px;
  padding-top: 25px;
  border-radius: 10px;
  width: 95%;
  align-self: center;
  margin-top: 10px;

  h3 {
    padding: 0 !important;
  }
}


.statistics__header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 40px;
  cursor: pointer;
  p {
    font-weight: bold;
    padding-bottom: 10px;
  }
}

.statistics__header--active {
  color: #33a0fc !important;
}

.modal__bonus--link {
  a {
    text-decoration: none;
    color: #529a6d;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}



.bonus__history {
  padding: 20px;
  min-height: 300px;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  opacity: 0.8;
}

.statistics {
  margin-top: 20px;

  .statistics__list {
    overflow-x: auto;
  }

  .statistics__list::-webkit-scrollbar {
    height: 1px;
  }

  .statistics__list::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: transparent;
  }

  .statistics__list::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
  }

  .statistics__title {
    font-size: 22px;
    color: white;
  }

  .item__statistic {
    margin-right: 20px;
    background-image: linear-gradient(
            to right,
            #253532,
            #1e363d,
            #273345,
            #3c2e43,
            #4c2934
    );
    min-width: 150px;
    padding: 20px;
    border-radius: 20px;
  }

  .item__statistic__month {
    color: white;
  }

  .item__statistic__freq {
    font-size: 22px;
    color: white;
    white-space: nowrap;
    color: #529a6d;
  }
}

.bonuses {
  margin-bottom: 60px;

  .bonuses__logo {
    font-size: 28px;

    i {
      color: white;
    }

    p {
      color: white;
    }
  }
}

.bonuses__search__area {
  border: 1px solid white;
  display: flex;
  margin-top: 10px;
  padding: 10px;

  i {
    color: black;
    font-size: 25px;
  }

  .bonuses__search {
    align-self: center;
    width: 100%;
    border-radius: 10px;
    color: #333333 !important;
    outline: none;
    border: 1px solid #333333;
    padding: 20px;
  }
}

.bonuses__amount {
  color: #529a6d !important;
  font-size: 18px;
}

.bonuses__result {
  color: white;
  font-size: 18px;
  margin-bottom: 20px;

  span {
    font-weight: bold;
    font-size: 24px;
  }

  .bonuses__result__text {
    font-size: 14px;
  }
}

::placeholder {
  color: #333333;
}

.bonuses__list {
  min-height: 180px;
  align-self: center;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin-bottom: 40px;
  outline: none;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  p {
    color: #333333;
  }

  .bonuses__right__actions {
    font-size: 24px;

    .mdi-account-edit-outline {
      position: absolute;
      right: 10px;
      top: 10px;
      color: white;
    }
  }

  .bonuses__count {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 24px;
    border-radius: 50%;
    border: 1px solid white;

    span {
      font-size: 14px;
    }
  }

  //background-image: linear-gradient(
  //        to right,
  //        #253532,
  //        #1e363d,
  //        #273345,
  //        #3c2e43,
  //        #4c2934
  //);

  @media (max-width: 900px) {
    width: 95%;
    margin-bottom: 40px;
  }

  .bonuses__list__name {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-weight: bold;
    }
  }

  .bonuses__delete {
    margin-top: 15px;
    color: white;
    font-weight: bold;
  }

  .bonuses__delete:hover {
    opacity: 0.7;
  }

  .bonuses__list__date {
    width: 200px;

    p {
      white-space: nowrap !important;
      color: white;
    }
  }
}

p {
  margin-bottom: 0;
}

.main__next {
  cursor: pointer;
  background: var(--main-kenes-blue);
  padding: 10px;
  margin-bottom: 20px;
  color: white;
  width: 200px;
  font-weight: bold;
  align-self: center;
  @media (max-width: 900px) {
    width: 100%;
  }
}

.main__next:hover {
  opacity: 0.8;
  color: black;
}

.set__category {
  flex-wrap: wrap;

  .set__category__input {
    width: 500px;
  }
}

.set__category__item {
  border-radius: 4px;
  width: 500px;
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 15px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
  rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  @media (max-width: 900px) {
    width: 100%;
  }

  .set__category__btn {
    padding: 10px;
    border: 1px solid black;
    color: white;
    background-color: var(--main-kenes-blue);
  }
}

.modal {
  padding: 20px;

  .modal__v {
    width: 150px;

    .modal__round {
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
  }
}

fieldset {
  border-color: blue !important;
}

.green__message {
  align-items: center;

  .green__input {
    width: 100%;
  }
}

.bonus__logs--history {
  align-self: flex-end;
}

.bonus__logs {
  i {
    padding: 5px;
    font-size: 18px;
    font-weight: bold;
  }

  .bonus__logs--item {
    margin-left: 15px;
    padding: 1px;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

.bonuses__end {
  color: #1e7e34;
  font-weight: bold;
}


.modal__bonus {
  .modal__bonus__title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    margin-bottom: 30px !important;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px !important;
  }

  .modal__bonus__result {
    margin-bottom: 10px;

    p {
      font-size: 18px;
      color: #333333;

      span {
        font-weight: bold;

        font-size: 20px;
      }
    }
  }

  .modal__bonus__result--value {
    color: #1e7e34 !important;
  }

  .modal__bonus__label {
    margin-bottom: 15px;

    p {
      font-weight: bold;
    }
  }

  input {
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
  }

  .modal__bonus__btn {
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    justify-content: center;
    background: black;

    p {
      color: white;
      font-weight: bold;
    }

    i {
      color: white;
    }
  }
}

.v-text-field--outlined fieldset {
  border-color: white;
}


.bonuses__list__phone {
  text-decoration: none;
  font-weight: bold;
  color: #529a6d;
  width: 175px;

  display: flex;
  align-items: center;
  margin-bottom: 18px !important;
}

.bonuses__header {
  justify-content: space-between;
  padding: 20px;

  h3 {
    padding :0;
  }
}

.bonuses__sub {
  background: #529a6d;
  padding: 10px;
  border: 0;
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 15px;


  p {
    margin-bottom: 0;
    color: white;
  }

}

.modal__bonus__variants {
  width: 100%;
  justify-content: space-around;

  button {
    width: 120px;
  }
}

.modal__bonus__btn--green {
  background: #529a6d !important;
}

.modal__bonus__btn--red {
  background: #e12910 !important;
}

.block__company {
  width: 100%;
  text-align: center;
  p {
    font-size: 28px;
  }
  a {
    font-weight: bold;
    font-size: 22px;
  }
}
</style>
